<template>
  <div>
    <div class="col-sm-10 job-candidate-status border-light">
      <h5 class="text-primary text-center mt-3">
        Job and Candidate Status Overview
      </h5>
      <CRow class="mt-3">
        <CCol md="4">
          <CRow class="row pb-3">
            <label class="col-lg-12 col-md-12">Jobs</label>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <Select
                name="job"
                :value="filters.job"
                @input="handleChangeSelect"
                :options="options && options['job'] ? options['job'] : []"
                :taggable="false"
                :multiple="false"
                :clearable="false"
              />
            </div>
          </CRow>
        </CCol>
        <CCol md="4">
          <CRow class="row mb-3">
            <label class="col-lg-12 col-md-12">Status</label>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <Select
                name="tab"
                :value="filters.tab"
                @input="handleChangeSelect"
                :options="options && options['tab'] ? options['tab'] : []"
                :taggable="false"
                :multiple="false"
                :clearable="true"
              />
            </div>
          </CRow>
        </CCol>
        <CCol md="4">
          <CRow class="row mb-3">
            <label class="col-lg-12 col-md-12">Sub Status</label>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <Select
                name="tabFilter"
                :value="filters.tabFilter"
                @input="handleChangeSelect"
                :options="
                  options && options['tabFilter'] ? options['tabFilter'] : []
                "
                :taggable="false"
                :multiple="false"
                :clearable="true"
                :disabled="
                  filtersCode.tab && filtersCode.tab != 'allTab' ? false : true
                "
              />
            </div>
          </CRow>
        </CCol>
      </CRow>
      <CRow>
        <CCol
          md="4"
          v-if="filtersCode.tabFilter && options['tabSubFilter'].length"
        >
          <CRow class="row mb-3">
            <label class="col-lg-12 col-md-12">Additional Filters</label>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <Select
                name="tabSubFilter"
                :value="filters.tabSubFilter"
                @input="handleChangeSelect"
                :options="
                  options && options['tabSubFilter']
                    ? options['tabSubFilter']
                    : []
                "
                :taggable="false"
                :multiple="false"
                :clearable="true"
                :disabled="filtersCode.tabFilter ? false : true"
              />
            </div>
          </CRow>
        </CCol>
        <CCol>
          <CRow class="ml-1">
            <label
              v-if="filtersCode.tabFilter && options['tabSubFilter'].length"
              class="text-white hidden-label col-12"
              >Hidden Label</label
            >
            <CButton
              class="btn-primary rounded-0 small"
              @click="$router.push('/job-candidates-status')"
              >Clear All</CButton
            >
            <h5 class="mt-1 ml-3">
              Search Results: {{ getJobCandidatesByStatusIDCount }}
            </h5>
          </CRow>
        </CCol>
      </CRow>
      <hr class="mb-4" />
      <CRow class="mb-3">
        <CCol
          v-for="(item, index) in getJobCandidatesByStatusID"
          :key="index"
          sm="6"
          md="3"
        >
          <div v-if="isFetchingJobCandidateSts" class="skeleton-card"></div>
          <candidate-info-card v-else :item="item" />
        </CCol>
        <CCol
          class="d-flex justify-content-center p-4"
          v-if="
            !getJobCandidatesByStatusID.length &&
              !isFetchingJobCandidateSts &&
              !isFetchingJobCandidateStsFilter
          "
        >
          <h1>No Data found!</h1>
        </CCol>
      </CRow>
      <CRow
        class="d-flex justify-content-around align-items-center"
        style="height: 200px"
        v-if="isFetchingJobCandidateSts"
      >
        <CCol>
          <div class="has-loading-overlay">
            <LoadingOverlay />
          </div>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import Select from "../components/reusable/Fields/Select.vue";
import {
  getFilterQueryStringWithoutArray,
  deepClone,
} from "@/helpers/helper.js";
import CandidateInfoCard from "../containers/JobCandidateStatus/CandidateInfoCard.vue";

export default {
  components: { Select, CandidateInfoCard },
  name: "JobCandidatesStatus",
  data: () => ({
    filters: {},
    filtersCode: {},
  }),
  computed: {
    ...mapGetters([
      "isFetchingJobCandidateSts",
      "isFetchingJobCandidateStsFilter",
      "getAllJobsFilterList",
      "getJobCandidateTabs",
      "getTabFilterOptions",
      "getTabSubFilterOptions",
      "getJobCandidatesByStatusID",
      "getJobCandidatesByStatusIDCount",
      "getJobCandidateStatusPagination",
    ]),
    options() {
      return {
        job: [
          {
            label: "All Jobs",
            code: "all",
          },
          ...this.getAllJobsFilterList,
        ],
        tab: [
          { label: "All", code: "allTab" },
          ...this.getJobCandidateTabs?.map((val) => ({
            label: val.tab_name,
            code: val.tab_id,
          })),
        ],
        tabFilter: this.getTabFilterOptions,
        tabSubFilter: this.getTabSubFilterOptions,
      };
    },
  },
  beforeDestroy() {
    const ele = document.getElementById("c-body-app");
    if (ele) {
      ele.removeEventListener("scroll", this.onBodyScroll);
    }
  },
  methods: {
    ...mapActions([
      "initJobCandidatesStatusFetchOptions",
      "fetchJobTabFilterOptions",
      "fetchJobTabSubFilterOptions",
      "fetchDisplayStatusSubStatus",
      "fetchJobCandidatesByStatusID",
      "setCurrentNavigationCandidatesPage",
      "fetchMoreCandidateList",
      "resetJobCandidateStatus",
    ]),
    handleChangeSelect(name, value) {
      Vue.set(this.filters, name, code);
      let code = value ? value.id || value.code || value : null;
      let newChange = deepClone(this.filtersCode);
      Vue.set(newChange, name, code);
      this.clearAndUpdateDependentFilters(newChange, name);
    },
    clearAndUpdateDependentFilters(newChange, name) {
      if (name == "tab") {
        newChange.tabFilter = null;
        if (newChange.tab && newChange.tab != "allTab") newChange.tabFilter = 2;
        newChange.tabSubFilter = null;
      }
      if (name == "tabFilter") {
        newChange.tabSubFilter = null;
      }
      this.updateUrl(newChange);
    },
    updateUrl(newChange) {
      for (const [key, value] of Object.entries(newChange)) {
        if ((key == "job" && value == "all") || !value) {
          delete newChange[key];
        }
      }
      this.$router.push(
        `/job-candidates-status?${getFilterQueryStringWithoutArray(newChange)}`
      );
    },
    fetchFilterOptions(name) {
      if (name == "tab" && this.filtersCode?.tab == "allTab") return;
      switch (name) {
        case "tab":
          return this.fetchJobTabFilterOptions({
            tab_id: this.filtersCode?.tab,
          });
        case "tabFilter":
          return this.fetchJobTabSubFilterOptions({
            tab_id: this.filtersCode?.tab,
            filter_id: this.filtersCode?.tabFilter,
          });
        default:
          break;
      }
    },
    onBodyScroll(e) {
      if (
        this.isFetchingJobCandidateSts ||
        this.getJobCandidateStatusPagination.noMoreCandidates
      )
        return;
      if (
        e.target.scrollHeight - e.target.scrollTop <=
        e.target.clientHeight + 1
      ) {
        this.fetchJobCandidatesByStatusID({ pagination: true });
      }
    },
    fetchCandidates() {
      let query = this.$router.currentRoute.query;
      let appendAction = [];
      let tempFiltersCode = deepClone(this.filtersCode);
      this.filtersCode = {};
      for (const [key, value] of Object.entries(query)) {
        this.filtersCode[key] = value;
        if (tempFiltersCode[key] == value) continue;
        appendAction.push(this.fetchFilterOptions(key));
      }
      Promise.all(appendAction).then((response) => {
        this.filters = { job: { label: "All Jobs", code: "all" } };
        for (const [key, value] of Object.entries(query)) {
          this.filters[key] = this.options[key].filter(
            (val) => value == val.code
          )[0];
        }
      });
      this.fetchDisplayStatusSubStatus().then((finalResponse) => {
        this.fetchJobCandidatesByStatusID({ pagination: false });
      });
    },
  },
  watch: {
    "$route.query": function() {
      this.fetchCandidates();
    },
  },
  mounted() {
    this.resetJobCandidateStatus();
    this.initJobCandidatesStatusFetchOptions().then((res) => {
      this.fetchCandidates();
    });
    const ele = document.getElementById("c-body-app");
    if (ele) {
      ele.addEventListener("scroll", this.onBodyScroll);
    }
    //For setting getNextCandidateId and getPreviousCandidateId function according to current Page
    let page = {
      currentPage: this.$router.currentRoute.name,
    };
    this.setCurrentNavigationCandidatesPage(page);
  },
};
</script>

<style lang="scss" scoped>
.job-candidate-status {
  background-color: white;
  margin: auto;
  min-height: 325px;
}
.filterheight {
  height: 80px;
}
@media all and (max-width: 480px) {
  .col-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .filterheight {
    flex: 0 0 98.33333%;
    max-width: 98.33333%;
  }
  .v-select {
    width: 250px;
  }
}
@media all and (max-width: 768px) {
  .hidden-label {
    display: none;
  }
}
</style>

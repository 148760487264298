<template>
  <div>
    <CCard class="candidate-card" style="min-height: 340px">
      <CCardBody>
        <div class="content-job-title mb-3">
          <div class="image-wrapper">
            <img
              :src="getProfilePic(candidateInfo)"
              class="rounded-circle"
              alt="DP"
            />
          </div>
          <span class="d-block"
            ><h5
              class="m-0 mt-2"
              :style="`color: ${getGenderColor(candidateInfo.gender)}`"
            >
              <a
                class="cursor-action"
                @click="navigateToCandidateDetail(candidateInfo)"
              >
                {{ toTitleCase(candidateInfo.first_name) }}
                {{ toTitleCase(candidateInfo.surname) }}
              </a>
            </h5></span
          >
          <span class="d-block">
            <span class="text-muted">{{
              candidateInfo.candidate_type.candidate_type
            }}</span>
          </span>
          <span class="d-block"></span>
          <span
            class="d-block"
            v-if="candidateInfo.speciality || candidateInfo.qualifications"
            >{{ speciality(candidateInfo) }}
          </span>
          <span class="d-block">{{ getCountry(candidateInfo) }}</span>
          <p class="mt-1">
            <a
              class="cursor-action link"
              @click="navigateToJobListPreview(item)"
              >Job ID: {{ item.job_id }}
            </a>
          </p>
        </div>
        <div class="candidate-status">
          <CBadge color="success" class="font-weight-normal" v-if="job_applied">
            <span>Applied</span></CBadge
          >
          <CBadge color="primary" class="font-weight-normal" v-else
            ><span>Talent Pool</span></CBadge
          >
          <CBadge
            color="matched"
            class="font-weight-normal ml-1 text-white"
            v-if="item.status && item.status['display_text']"
            ><span>{{ item.status["display_text"] }}</span></CBadge
          >
          <CBadge
            color="warning"
            class="font-weight-normal ml-1 text-white"
            v-if="item.sub_status && item.sub_status['display_text']"
            ><span>{{ item.sub_status["display_text"] }}</span></CBadge
          >
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "CandidateInfoCard",
  props: ["item"],
  computed: {
    ...mapGetters(["getGenderColor"]),
    candidateInfo() {
      return this.item?.candidate;
    },
    job_applied() {
      return this.item?.job_applied;
    },
  },
  methods: {
    ...mapActions(["setCandidateListBackUrl", "resetCandidateDetail"]),
    toTitleCase(str) {
      return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    speciality(data) {
      let highestQualification = null;
      data.qualifications &&
        data.qualifications.length &&
        data.qualifications.some((e) => {
          if (e.main) {
            highestQualification = e;
          }
        });
      let speciality = data.speciality?.speciality || null;
      let sub_speciality = data.sub_speciality?.sub_speciality || null;
      highestQualification = highestQualification
        ? highestQualification.qualification.qualification_with_acronym
        : null;
      if (highestQualification) {
        if (sub_speciality) {
          return speciality + "|" + sub_speciality + "|" + highestQualification;
        } else if (speciality) {
          return speciality + "|" + highestQualification;
        } else {
          return highestQualification;
        }
      } else {
        if (sub_speciality) {
          return speciality + "|" + sub_speciality;
        } else if (speciality) {
          return speciality;
        } else {
          return null;
        }
      }
    },
    getCountry(data) {
      return data.location?.country?.country_name || null;
    },
    getProfilePic(data) {
      return data.profile_picture;
    },
    navigateToCandidateDetail(data) {
      this.setCandidateListBackUrl(this.$route.fullPath);
      this.resetCandidateDetail();
      this.$router.push({ path: `/candidate/${data.candidate_uid}` });
    },
    navigateToJobListPreview(item) {
      let fullPath = this.$route.fullPath;
      this.$store.commit("SET_JOB_LIST_BACK_URL", fullPath);
      this.$router.push({ path: `/job-list-preview/${item?.job_uuid}` });
    },
  },
};
</script>
<style lang="scss" scoped>
.content-job-title {
  text-align: center;
  .image-wrapper img {
    width: 80px;
  }
}
.cursor-action {
  cursor: pointer;
}
.candidate-status {
  position: absolute;
  bottom: 1.24em;
  left: 1.4em;
  z-index: 1;
}
.link {
  color: blue;
  font-size: 13px;
  font-weight: 400;
}

a:hover {
  color: #dc0050;
  text-decoration: underline;
}
</style>
